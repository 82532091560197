<template>
	<section class="scanner-grid-section">
		<Row class="">
			<Container class="flex flex-wrap items-center">
				<h2
					id="scanner-pitch-title"
					class="text-white text-[32px] lg:text-6xl font-medium mb-[32px] lg:mb-[64px] text-center w-full max-w-[848px] text-center mx-auto"
				>
					Stay Ahead of the Curve with our AI and Algorithm-Enhanced Tools
				</h2>
				<div class="flex flex-wrap justify-between gap-x-10 gap-y-10">
					<div class="scanner scanner-big full-width bg-light">
						<div class="w-full md:w-2/5 pr-[24px]">
							<h3 class="scanner-title text-black">Oracle Scanner</h3>
							<p class="scanner-paragraph text-black pt-3">
								The Oracle Algorithm sifts through 15,000 stocks every second, searching
								for 5-to-1 risk ratios, generating 15 trading opportunities a day.
							</p>
							<div class="pt-[24px]">
								<CtaButton
									height="66"
									:to="'https://lp.stockstotrade.com/algo-alerts/'"
									buttonClass="w-full lg:w-auto justify-center"
									:type="ctaAppearence || 'gradient-dark'"
									:blank="true"
								>
									{{ "Get Daily Alerts" }}
								</CtaButton>
							</div>
							<span
								data-animate="true"
								id="scanner-pitch-1-img-track"
							/>
						</div>

						<video
							id="scanner-pitch-1-img"
							v-if="isDesktop"
							loading="lazy"
							width="956"
							height="380"
							class="scanner-big-image rounded-t-[12px] lg:rounded-t-[16px]"
							autoplay
							playsinline
							loop
							muted
						>
							<source
								src="/media/stt-video-2.webm"
								type="video/webm"
							/>

							<source
								src="/media/stt-video-2.mp4"
								type="video/mp4"
							/>
							Your browser does not support the video tag.
						</video>
						<img
							v-else
							width="724"
							height="375"
							id="scanner-pitch-1-img"
							class="scanner-big-image rounded-t-[12px] lg:rounded-t-[16px]"
							alt="scanner-pitch-1-img"
							src="/images/stt-video-2.webp"
						/>
					</div>
					<div class="scanner scanner-small half-width bg-semi-light">
						<div class="pb-6 pr-[24px]">
							<h3 class="scanner-title text-black text-left">Alpha Scanner</h3>
							<p class="scanner-paragraph text-black pt-3 text-left">
								StocksToTrade trainer Matt Monaco built this algorithm to match his
								successful trading strategy, and identify "dark" price anomalies
								happening inside the stock market in real time.
							</p>
							<div class="pt-[24px]">
								<CtaButton
									height="66"
									:to="'https://cfn.stockstotrade.com/hp-alpha-cart/'"
									buttonClass="w-full lg:w-auto justify-center"
									:type="ctaAppearence || 'gradient-dark'"
									:blank="true"
								>
									{{ "Learn More" }}
								</CtaButton>
							</div>
						</div>
						<img
							data-animate="true"
							id="scanner-pitch-2-img"
							class="scanner-small-img"
							src="/images/scanner-grid-2.webp"
							alt="scanner-grid-2.webp"
							width="572"
							height="264"
							loading="lazy"
						/>
					</div>
					<div class="scanner scanner-small half-width bg-dark">
						<div class="pb-6 pr-[24px]">
							<h3 class="scanner-title text-white text-left">IRIS Analytics</h3>
							<p class="scanner-paragraph text-white pt-3 text-left">
								StocksToTrade lead trainer Tim Bohen designed this AI system around his
								meticulous trading style — it's able to forecast explosive stock moves
								days and even weeks into the future!
							</p>
							<div class="pt-[24px]">
								<CtaButton
									height="66"
									:to="'https://cfn.beyondaievent.com/hp-iris-cart'"
									buttonClass="w-full lg:w-auto justify-center"
									:type="ctaAppearence || 'gradient-light'"
									:blank="true"
								>
									{{ "Learn More" }}
								</CtaButton>
							</div>
						</div>

						<img
							id="scanner-pitch-3-img"
							data-animate="true"
							class="scanner-small-img"
							src="/images/scanner-grid-3.webp"
							alt="scanner-grid-3.webp"
							width="572"
							height="264"
							loading="lazy"
						/>
					</div>
				</div>
			</Container>
		</Row>
	</section>
</template>

<script setup>
const { ctaAppearence } = defineProps({
	ctaAppearence: { type: String }
});

const isDesktop = ref(false);

const breakpoints = reactive(
	useBreakpoints({
		small: 768
	})
);

onMounted(async () => {
	if (process.client && breakpoints.isGreater("small")) {
		isDesktop.value = true;
	}
});
</script>

<style lang="scss" scoped>
.scanner-grid-section {
	.full-width {
		width: 100%;
	}

	.scanner {
		min-height: 100px;
		border-radius: 16px;
		padding: 32px 0 0 20px;
		text-align: left;
		position: relative;
		overflow: hidden;
		@include breakpoint(lg) {
			padding: 32px 0 0 32px;
			max-height: 540px;
		}
		img {
			border-radius: 8px 0px 0px 0px;
			margin-left: auto;
			margin-bottom: -12px;

			@include breakpoint(lg) {
				border-radius: 16px 0px 0px 0px;
			}
		}

		&-big {
			padding-top: 120px;
			padding-bottom: 24px;

			@include breakpoint(lg) {
				padding-bottom: 100px;
			}
			&-image {
				right: -16px;
				bottom: 0;
				left: 40px;
				position: relative;
				top: 24px;

				@include breakpoint(lg) {
					width: 904px;
					position: absolute;
					min-height: 380px;
					max-height: 380px;
					left: auto;
					top: 64px;
				}
			}

			@media only screen and (max-width: 768px) {
				padding-top: 32px;
				padding-bottom: 0;
			}
		}

		&-small {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@media only screen and (max-width: 768px) {
				width: 100%;
				flex: auto !important;
			}

			&-img {
				//height: 300px;
			}
		}

		&-title {
			font-family: Open Sauce Sans;
			font-size: 32px;
			font-weight: 500;
			line-height: 35px;
			letter-spacing: -2px;
			text-align: left;
		}
		&-paragraph {
			font-family: DM Sans;
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;
			letter-spacing: 0px;
			text-align: left;
		}
	}

	.half-width {
		flex: 1;
	}

	.bg-light {
		background: linear-gradient(167.41deg, #c3b9ff 19.11%, #364bbc 69.51%);
	}

	.bg-semi-light {
		background: linear-gradient(107.69deg, #87c7f5 15.06%, #c464ff 89.01%);
	}

	.bg-dark {
		background: linear-gradient(110.32deg, #091d56 8.05%, #331290 77.58%);
	}
}

#scanner-pitch-1-img {
	@include breakpoint(lg) {
		// opacity: 0;
		right: -224px;
	}
}
</style>
